<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline">Preguntas de {{ item.name }}</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
      <v-container fluid class="pa-5">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              placeholder="Buscar"
              rounded
              height="50"
              filled
              v-model="search"
            />
          </v-col>

          <v-col class="align-end" cols="12" sm="12" md="12">
            <v-btn
              color="buttons"
              @click="showaddDialog(null)"
              :loading="loading"
            >
              <v-icon left> fas fa-plus </v-icon>
              {{ $t("add") }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items="questions"
              :search="search"
              :items-per-page="10"
              :loading="loading"
              :sort-by="['position']"
              :sort-desc="true"
              item-key=".key"
              fixed-header
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.active`]="{ item }">
                <v-switch
                  @change="activeUpdated($event, item)"
                  :loading="loading"
                  v-model="item.active"
                ></v-switch>
              </template>

              <template v-slot:[`item.answers`]="{ item }">
                {{ item.answers.length }}
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      :loading="loading"
                      v-on="on"
                      dark
                      @click="showaddDialog(item)"
                      x-small
                      :elevation="0"
                      color="#646464"
                    >
                      <v-icon dark>fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Respuestas</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      :loading="loading"
                      v-on="on"
                      dark
                      @click="showDeleteDialog(item)"
                      x-small
                      :elevation="0"
                      color="red darken-4"
                    >
                      <v-icon dark>fa-trash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc("table.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-dialog
      persistent
      max-width="500px"
      v-if="deleteDialog"
      v-model="deleteDialog"
    >
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.title"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>

    <v-dialog persistent max-width="900px" v-if="addDialog" v-model="addDialog">
      <add
        :gameId="this.item['.key']"
        @success="handleSuccess"
        @cancel="addDialog = false"
        :originalItem="selectedItem"
      />
    </v-dialog>
  </v-card>
</template>
  
  <script>
// import { db } from "@/firebase";
import { mapState } from "vuex";
//   import general from "./tabs/general";
//   import styles from "./tabs/styles";
//   import setting from "./tabs/setting";
import { db } from "@/firebase";
import deleteItem from "@/components/delete";
import add from "./add.vue";

export default {
  name: "questions-trivia",
  props: ["item"],
  components: {
    deleteItem,
    add,
    //   general,
    //   styles,
    //   setting,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      search: "",
      questions: [],
      addDialog: false,
      deleteDialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Título",
          value: "title",
        },

        {
          text: "Respuestas",
          value: "answers",
        },

        {
          text: "Activar",
          value: "active",
        },

        {
          text: "",
          value: "options",
          align: "end",
          width: "200px",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    handleSuccess() {
      this.addDialog = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },
    activeUpdated(event, item) {
      this.loading = true;

      db.collection("games")
        .doc(this.item[".key"])
        .collection("questions")
        .doc(item[".key"])
        .update({
          active: event,
        })
        .then(() => {
          this.snackbarText = "Información actualizada correctamente.";
          this.snackbar = true;
          this.loading = false;
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;

          item.active = !event;
          this.loading = false;
        });
    },
    showaddDialog(item) {
      if (item) {
        this.selectedItem = item;
      } else {
        this.selectedItem = null;
      }
      this.addDialog = true;
    },
    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("games")
        .doc(this.item[".key"])
        .collection("questions")
        .doc(this.selectedItem[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$t("information_updated");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.snackbarText = this.$t("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
    showDeleteDialog(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
  },
  async mounted() {
    await this.$binding(
      "questions",
      db.collection("games").doc(this.item[".key"]).collection("questions")
    );
    this.loading = false;
  },
};
</script>