<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $t("trivia.title") }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            @click="showaddDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $t("add") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="games"
            @wrongSoundChanged="updateFlag($event, 'playWrongSound')"
            @correctSoundChanged="updateFlag($event, 'playCorrectSound')"
            @activeChange="switchActiveChanged"
            :showEdit="true"
            :showDelete="true"
            :showQuestions="true"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      max-width="900px"
      transition="dialog-bottom-transition"
      v-if="addDialog"
      persistent
      v-model="addDialog"
      style="overflow: hidden !important"
    >
      <add
        @success="handleSuccess"
        :item="selectedItem"
        @cancel="addDialog = false"
      />
    </v-dialog>

    <v-dialog
      persistent
      max-width="500px"
      v-if="deleteDialog"
      v-model="deleteDialog"
    >
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>

    <v-dialog
      persistent
      max-width="80%"
      v-if="questionsDialog"
      v-model="questionsDialog"
    >
      <questions :item="selectedItem" @cancel="questionsDialog = false" />
    </v-dialog>
  </div>
</template>
  
  <script>
import { db } from "@/firebase";
import add from "./add";
import filesTable from "@/components/files-table";
import deleteItem from "@/components/delete";
import { mapActions, mapState } from "vuex";
import questions from "./questions/list.vue";

export default {
  name: "trivia",
  components: {
    // addZone,
    add,
    filesTable,
    deleteItem,
    questions,
  },
  data() {
    return {
      selectedItem: "",
      addDialog: "",
      deleteDialog: false,
      snackbarText: "",
      snackbar: false,
      loading: true,
      questionsDialog: false,
      //   zones: [],
      games: [],
      headers: [
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.description"),
          value: "description",
          width: "200px",
        },

        {
          text: this.$t("headers.play_correct_sound"),
          value: "playCorrectSound",
        },

        {
          text: this.$t("headers.play_wrong_sound"),
          value: "playWrongSound",
        },

        {
          text: this.$t("headers.active"),
          value: "active",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId", "user"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),

    switchActiveChanged(data) {
      console.log(data);
      this.loading = true;
      db.collection("games")
        .doc(data[".key"])
        .update({
          active: data.active,
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
        })
        .then(() => {
          this.loading = false;
          this.snackbarText = this.$tc("information_updated");

          this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },
    showaddDialog() {
      this.selectedItem = null;
      this.addDialog = true;
    },
    editGame(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    showQuestionsDialog(item) {
      this.selectedItem = item;
      this.questionsDialog = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "edit":
          this.editGame(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;

        case "questions":
          this.showQuestionsDialog(payload.item);
          break;
      }
    },

    async updateFlag(event, flag) {
      this.loading = true;
      await db
        .collection("games")
        .doc(event[".key"])
        .update({
          [flag]: event[flag],
        });

      this.snackbarText = this.$tc("information_updated");
      this.snackbar = true;
      this.loading = false;
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.addDialog = false;
    },

    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("games")
        .doc(this.selectedItem[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$t("information_updated");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.snackbarText = this.$t("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
  },
  async mounted() {
    this.cleanSearch();

    await this.$binding(
      "games",
      db
        .collection("games")
        .where("type", "==", "trivia")
        .where("groupId", "==", this.groupId)
    );

    this.loading = false;

    // db.collection("games")
    //   .where("type", "==", "trivia")
    //   .where("groupId", "==", this.groupId)
    //   .onSnapshot((response) => {
    //     this.zones = [];
    //     response.forEach((item) => {
    //       let zone = item.data();
    //       zone[".key"] = item.id;
    //       this.zones.push(zone);
    //     });
    //     this.loading = false;
    //   });
  },
};
</script>
  
  <style scoped>
.v-dialog--fullscreen {
  overflow: hidden !important;
}
</style>