<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline">{{ item ? "Editar" : "Agregar" }} trivia</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
      <v-container class="pa-5">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-tabs v-model="tab">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab key="general">General</v-tab>
              <v-tab key="style">Estilos</v-tab>
            </v-tabs>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="general">
                <general :loading="loading" :game="game" />
              </v-tab-item>

              <v-tab-item key="style">
                <styles :loading="loading" :game="game" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="3" md="3">
            <v-switch v-model="game.active" label="Activar" />
          </v-col>
          <v-col
            style="display: flex; justify-content: end; align-items: center"
            cols="12"
            sm="9"
            md="9"
          >
            <v-btn
              :loading="loading"
              @click="item ? update() : save()"
              class="save-btn"
              color="buttons"
              >{{ $t("button.save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
// import { db } from "@/firebase";
import { mapState } from "vuex";
import general from "./tabs/general";
import styles from "./tabs/styles";
import setting from "./tabs/setting";
import { db } from "../../../firebase";

export default {
  name: "add-trivia",
  props: ["item"],
  components: {
    general,
    styles,
    setting,
  },
  data() {
    return {
      snackbarText: "",
      agency: {},
      snackbar: false,
      tab: "",
      loading: false,
      game: {
        name: "",
        deleted: false,
        type: "trivia",
        backgroundStyles: [
          {
            property: "backgroundColor",
            value: "#ff5900",
            control: "color",
          },

          {
            property: "backgroundImage",
            value: "http://",
            control: "image",
          },
          {
            property: "backgroundRepeat",
            value: "",
            control: "repeat",
          },
        ],

        titleStyles: [
          {
            property: "color",
            value: "#a8a8a8",
            control: "color",
          },
          {
            property: "fontSize",
            value: "2vw",
            control: "size",
          },

          {
            property: "fontFamily",
            value: "comic sans ms, cursive",
            control: "font",
          },
        ],

        buttonStyles: [
          {
            property: "fontWeight",
            value: "bold",
            control: "weight",
          },
          {
            property: "fontSize",
            value: "2vw",
            control: "size",
          },
          {
            property: "backgroundColor",
            value: "#ff5900",
            control: "color",
          },
          {
            property: "color",
            value: "#a8a8a8",
            control: "color",
          },
          {
            property: "fontFamily",
            value: "comic sans ms, cursive",
            control: "font",
          },
          {
            property: "borderRadius",
            value: "2vw",
            control: "size",
          },
        ],
        questionStyles: [
          {
            property: "fontWeight",
            value: "bold",
            control: "weight",
          },
          {
            property: "fontSize",
            value: "2vw",
            control: "size",
          },

          {
            property: "color",
            value: "#a8a8a8",
            control: "color",
          },
          {
            property: "fontFamily",
            value: "comic sans ms, cursive",
            control: "font",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    update() {
      if (!this.game.name) {
        this.snackbarText = "Debe ingresar el título de la trivia";
        this.snackbar = true;

        return;
      }
      this.loading = true;
      this.game.modifiedAt = new Date();
      this.game.modifiedBy = this.user.uid;

      db.collection("games")
        .doc(this.item[".key"])
        .update({
          name: this.game.name,
          questionsToShow: this.game.questionsToShow || 0,
          description: this.game.description || "",
          playCorrectSound: this.game.playCorrectSound || false,
          playWrongSound: this.game.playWrongSound || false,
          wrongVolume: this.game.wrongVolume || 0,
          active: this.game.active || false,
          backgroundStyles: this.game.backgroundStyles || [],
          titleStyles: this.game.titleStyles || [],
          buttonStyles: this.game.buttonStyles || [],
          questionStyles: this.game.questionStyles || [],
          showRoulette: this.game.showRoulette || false,
          showPlaylist: this.game.showPlaylist || false,
          rouletteId: this.game.rouletteId || "",
          playlist: this.game.playlist || "",
          timeToAnswer: Number(this.game.timeToAnswer) || 0,
          showSquares: this.game.showSquares || false,
          inactiveTime: this.game.inactiveTime
            ? Number(this.game.inactiveTime)
            : 20,
        })
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
    save() {
      if (!this.game.name) {
        this.snackbarText = "Debe ingresar el título de la trivia";
        this.snackbar = true;

        return;
      }
      this.loading = true;
      this.game.groupId = this.groupId;
      this.game.createdAt = new Date();
      this.game.createdBy = this.user.uid;

      db.collection("games")
        .add(this.game)
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.loading = false;

    if (this.item) {
      this.game = Object.assign({}, this.item);
    }
  },
};
</script>