<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline"
        >{{ originalItem ? "Editar" : "Agregar" }} pregunta</span
      >
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
      <v-container fluid class="pa-5">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              rounded
              filled
              label="Título de la pregunta"
              v-model="question.title"
            />
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <span class="font-weight-bold">Respuestas</span>
            <v-divider />

            <v-row class="mt-5" v-for="(ans, id) of question.answers" :key="id">
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  rounded
                  filled
                  :rules="[rules.required, rules.max]"
                  @keypress="maxLength($event, ans.text, 115)"
                  v-model="ans.text"
                  label="Ingresar la respuesta"
                />
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  @change="checkCorrectAnswer($event, id)"
                  v-model="ans.correctAnswer"
                  label="Respuesta correcta"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="question.answers.length < 5"
            class=""
            cols="12"
            sm="12"
            md="12"
          >
            <v-btn
              @click="addAnswer"
              class="white--text"
              rounded
              color="#727272"
            >
              <v-icon left> fas fa-plus </v-icon>
              Agregar respuesta</v-btn
            >
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="3" md="3">
            <v-switch v-model="question.active" label="Activar" />
          </v-col>
          <v-col
            style="display: flex; justify-content: end; align-items: center"
            cols="12"
            sm="9"
            md="9"
          >
            <v-btn
              :loading="loading"
              @click="originalItem ? update() : save()"
              class="save-btn"
              color="buttons"
              >{{ $t("button.save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
    
    <script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  name: "add-question",
  props: ["originalItem", "gameId"],

  data() {
    return {
      snackbarText: "",
      snackbar: false,
      rules: {
        required: (value) => !!value || "Obligatorio",
        max: (v) => (v && v.length < 115) || "Máximo 115 caracteres",
      },
      question: {
        answers: [{ text: "", correctAnswer: false }],
      },
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    maxLength(evt, field, length) {
      console.log("sjsj");
      if (field.length >= length) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    update() {
      if (!this.question.title) {
        this.snackbarText = "Debe ingresar el título de la pregunta.";
        this.snackbar = true;
        return;
      }
      this.question.answers = this.question.answers.filter((e) => e.text);

      this.loading = true;

      this.question.modifiedBy = this.user.uid;
      this.question.modifiedAt = new Date();

      db.collection("games")
        .doc(this.gameId)
        .collection("questions")
        .doc(this.originalItem[".key"])
        .update({
          answers: this.question.answers,
          title: this.question.title,
          active: this.question.active,
        })
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
    save() {
      if (!this.question.title) {
        this.snackbarText = "Debe ingresar el título de la pregunta.";
        this.snackbar = true;
        return;
      }
      this.question.answers = this.question.answers.filter((e) => e.text);

      this.loading = true;

      this.question.createdBy = this.user.uid;
      this.question.createdAt = new Date();
      this.question.groupId = this.groupId;

      db.collection("games")
        .doc(this.gameId)
        .collection("questions")
        .add(this.question)
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },

    checkCorrectAnswer(e, id) {
      for (let i = 0; i < this.question.answers.length; i++) {
        if (e && i != id) {
          this.question.answers[i].correctAnswer = false;
        }
      }
    },

    addAnswer() {
      if (this.question.answers.length < 5) {
        this.question.answers.push({ text: "", correctAnswer: false });
      }
    },
  },

  mounted() {
    if (this.originalItem) {
      this.question = Object.assign({}, this.originalItem);
    }
  },
};
</script>