<template>
  <v-container>
    <v-row>
      <v-tabs color="secondary" v-model="tab">
        <v-tab key="background">Fondo</v-tab>
        <v-tab key="title">Título</v-tab>
        <v-tab key="buttons">Botones</v-tab>
        <v-tab key="questions">Preguntas</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="background" class="mt-10">
          <css :stylesArray="game.backgroundStyles" />
        </v-tab-item>

        <v-tab-item key="title" class="mt-10">
          <css :stylesArray="game.titleStyles" />
        </v-tab-item>

        <v-tab-item key="buttons" class="mt-10">
          <css :stylesArray="game.buttonStyles" />
        </v-tab-item>

        <v-tab-item key="questions" class="mt-10">
          <css :stylesArray="game.questionStyles" />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>


<script>
import css from "@/components/css";

export default {
  name: "styles-games",
  props: ["game", "loading"],

  components: {
    css,
  },

  data() {
    return {
      sizes: [],
      tab: null,
    };
  },
  computed: {},

  methods: {},

  //   mounted() {
  //     for (let i = 0; i < 95; i = i + 2) {
  //       this.sizes.push({ text: `${i + 2} Unidades`, value: `${i + 2}vw` });
  //     }
  //   },
};
</script>

<style scoped>
input[type="color"] {
  -webkit-appearance: none;
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
</style>