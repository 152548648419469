<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          rounded
          label="Ingrese el título de la trivia"
          filled
          :loading="loading"
          v-model="game.name"
          hide-details
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-text-field
          rounded
          label="Cantidad de preguntas a realizar al jugador"
          filled
          :loading="loading"
          v-model.number="game.questionsToShow"
          hide-details
        />
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-text-field
          rounded
          label="Tiempo para contestar una pregunta"
          filled
          :loading="loading"
          v-model.number="game.timeToAnswer"
          hide-details
        />
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-textarea
          rounded
          label="Ingrese la descripción"
          filled
          rows="2"
          :loading="loading"
          v-model="game.description"
        />
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-switch
          :loading="loading"
          v-model="game.showSquares"
          label="Mostrar animación burbuja"
        />
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-switch
          :loading="loading"
          v-model="game.playCorrectSound"
          label="Reproducir sonido con respuestas correctas"
        />
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-switch
          :loading="loading"
          v-model="game.playWrongSound"
          label="Reproducir sonido con respuestas incorrectas"
        />
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-slider
          :loading="loading"
          v-model="sound"
          label="Volumen de sonidos"
          min="0"
          max="100"
        ></v-slider>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-switch label="Mostrar ruleta" v-model="game.showRoulette"></v-switch>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-select
          rounded
          :disabled="!game.showRoulette"
          filled
          item-text="title"
          item-value=".key"
          label="Seleccionar ruleta"
          :items="roulettes"
          v-model="game.rouletteId"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-switch
          :loading="loading"
          v-model="game.showPlaylist"
          label="Mostrar lista de reproducción"
        />
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-select
          rounded
          :disabled="!game.showPlaylist"
          filled
          item-text="name"
          item-value=".key"
          label="Seleccionar lista de reproducción"
          :items="playlists"
          v-model="game.playlist"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-text-field
          rounded
          filled
          hint="Tiempo en segundos"
          :loading="loading"
          v-model.number="game.inactiveTime"
          label="Tiempo de inactividad para mostrar la lista de reproducción"
        />
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "trivia-general",
  props: ["game", "loading"],

  data() {
    return {
      sound: 0,
      roulettes: [],
      playlists: [],
    };
  },
  computed: {
    ...mapState(["groupId", "user"]),
  },

  watch: {
    sound(e) {
      this.game.wrongVolume = e / 100;
    },
  },

  mounted() {
    this.sound = this.game.wrongVolume * 100;

    this.$binding(
      "roulettes",
      db
        .collection("games")
        .where("type", "==", "roulette")
        .where("groupId", "==", this.groupId)
    );

    this.$binding(
      "playlists",
      db
        .collection("playlists")
        // .where("type", "==", "roulette")
        .where("groupId", "==", this.groupId)
    );
  },
};
</script>